import React from 'react';
import { Reveal } from "react-genie";
import { Animation } from "react-genie-styled-components";
import title from '../assets/image/contact-title.png';
import '../assets/css/Measures.css';

const Contact = () => {
    return (
        <div className='contact'>
            <img className='title' src={title} alt='感染症対策'　/>
            <Reveal animation={Animation.FadeInUp} delay={100}>
                <div className='covid-box'>
                    <div className='covid-info'>
                        <h2 className='covid-title'>
                            運営方針
                        </h2>
                        <p className='covid-txt'>
                            本ハッカソンの運営にあたりましては、政府の「新型コロナウイルス感染症対策の基本的対処方針」及び<br className='br-res' />
                            「新型コロナウイルス感染症が拡大する今野外に出るときに５つのガイドライン」に沿った対策を講じることに加え、<br className='br-res' />
                            行政機関からの指導のもと、参加者の皆さまとスタッフの健康と安全の確保を最優先に運営してまいります。
                        </p>
                        <p className='covid-txt'>
                            お客様・スタッフの検温・ソーシャルディスタンスを確保するための積極的な声かけ等、<br className='br-res' />
                            健康と安全のための対策を徹底し、慎重に運営してまいります。
                        </p>
                        <p className='covid-txt'>
                            参加者の皆さまに安心してお楽しみいただけるよう、スタッフ一丸となって、運営をしてまいります。<br className='br-res' />
                            参加者の皆さまにはご不便をお掛けする場合もあるかと存じますが、何卒ご理解・ご協力の程よろしくお願いいたします。
                        </p>
                    </div>
                    <div className='covid-info'>
                        <h2 className='covid-title'>
                            参加者へのお願い
                        </h2>
                        <p className='covid-txt'>
                            当日は、参加者へのマスク及び、アルコール消毒を人数分配布いたします。また、参加者の皆さまに検温も実施いたします。<br className='br-res' />
                            コロナ対策へのご協力をお願い申し上げます。
                        </p>
                        <p className='covid-txt'>
                            【下記に該当する方のご参加はご遠慮ください】<br />
                            ・新型コロナウイルス感染症陽性者との濃厚接触がある方<br />
                            ・同居家族や身近な知人に感染が疑われる場合がある方<br />
                            ・過去14日以内に政府から入国制限、入国後の観察期間を必要とされている国、地域等への渡航ならびに当該在住者との濃厚接触がある方
                        </p>
                        <p className='caution'>
                            ※公的機関（保健所等）から新型コロナウイルスの感染拡大防止を目的とする要請がある場合、参加者の個人情報を提供する場合があります 。
                        </p>
                    </div>
                </div>
            </Reveal>
        </div>
    );
}

export default Contact;