import React from 'react';
import { Reveal } from "react-genie";
import { Animation } from "react-genie-styled-components";
import flow from '../assets/image/flow-title.png';
import table from '../assets/image/flows-table.png';
import '../assets/css/flow.css';

const Flow = () => {
    return (
        <div className='flow'>
            <img className='flow-title' src={flow} alt='フロー'　/>
            <Reveal animation={Animation.FadeInUp} delay={100}>
                <div className='plan'>
                    <img className='table' src={table} alt='フローテーブル'　/>
                    <div className='table-box'>
                        <div className='flow-box'>
                            <p className='flow-txt'>7/19~9/11</p>
                            <div className='table-text-box'>
                                <h3 className='table-title'>とりあえずエントリー</h3>
                                <p className='table-txt'>右下のボタンを押すのだ！<br className='br-sp' />(チームは後からでもOK!)</p>
                            </div>
                        </div>
                        <div className='flow-box'>
                            <p className='flow-txt'>8月中旬頃</p>
                            <div className='table-text-box'>
                                <h3 className='table-title'>テーマ発表</h3>
                                <p className='table-txt'>完成度に拘りたい人は<br className='br-sp' />事前に作り始めてOK！</p>
                            </div>
                        </div>
                        <div className='flow-box'>
                            <p className='flow-txt'>9月中旬頃</p>
                            <div className='table-text-box'>
                                <h3 className='table-title'>オンライン説明会</h3>
                                <p className='table-txt'>当日の準備物などの連絡を<br className='br-sp' />オンラインで行うぞ！</p>
                            </div>
                        </div>
                        <div className='flow-box'>
                            <p className='flow-txt'>9/23~9/25</p>
                            <div className='table-text-box'>
                                <h3 className='table-title'>ハッカソン本番！</h3>
                                <p className='table-txt'>キャンプと開発を楽しもう！</p>
                            </div>
                        </div>
                    </div>
                </div>     
            </Reveal>
        </div>
)   ;
}

export default Flow;