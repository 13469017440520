import React from 'react';
import { ReactGenieAnimations } from "react-genie-styled-components";
import './App.css';
import Top from './components/Top'
import Explain from './components/Explain';
import Point from './components/Point';
import Flow from './components/Flow';
import Schedule from './components/Schedule';
import Theme from './components/Theme';
import Venue from './components/Venue';
import Freebie from './components/Freebie';
import Sponsor from './components/Sponsor';
import Measures from './components/Measures';
import Footer from './components/footer';

function App() {
  return (
    <>
      <ReactGenieAnimations />
      <Top />
      <Explain />
      <Point />
      <Flow />
      <Schedule />
      <Theme />
      <Venue />
      <Freebie />
      <Sponsor />
      <Measures />
      <Footer />
    </>
  );
}

export default App;
