import React from 'react';
import { Reveal } from "react-genie";
import { Animation } from "react-genie-styled-components";
import monster from '../assets/image/Freebie.png';
import title from '../assets/image/Freebie-title.png';
import '../assets/css/Freebie.css';

const Freebie = () => {
  return (
    <div className='Freebie'>
      <img className='Freebie-title' src={title} alt='景品'　/>
      <Reveal animation={Animation.FadeInUp} delay={100}>
        <div className='Freebie-box'>
          <p className='Freebie-txt'>Monster Energy<br/><span className='Emphasis'>1年分</span></p>
          <img className='monster' src={monster} alt='Monster Energy'　/>
        </div> 
      </Reveal>
    </div>
  );
}

export default Freebie;