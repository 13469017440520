import React from 'react';
import { Reveal } from "react-genie";
import { Animation } from "react-genie-styled-components";
import title from '../assets/image/sponsor-title.png';
import beenos from '../assets/image/beenos.png';
import excite from '../assets/image/excite.png';
import jig from '../assets/image/jig_jp.png';
import ernie from '../assets/image/ernie.png';
import mixi from '../assets/image/mixi.png';
import x from '../assets/image/x.png';
import '../assets/css/sponsor.css';

const Sponsor = () => {
    return(
        <div className='sponsor'>
            <img className='sponsor-title' src={title} alt='スポンサー'　/>
            <Reveal animation={Animation.FadeInUp} delay={100}>
                <div className='sponsor-box'>
                    <div className='gold'>
                        <h3　className='sponsor-txt'>ゴールドスポンサー</h3>
                        <a target="_blank" rel="noopener noreferrer" href='https://ernie.co.jp/'>
                            <img className='sponsor-logo' src={ernie} alt='アーニーMLG株式会社'　/>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://beenos.com/'>
                            <img className='sponsor-logo' src={beenos} alt='BEENOS株式会社'　/>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://info.excite.co.jp/corp.html'>
                            <img className='sponsor-logo' src={excite} alt='エキサイト株式会社'　/>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://jig.jp/'>
                            <img className='sponsor-logo' src={jig} alt='株式会社jig.jp'　/>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://mixi.co.jp/'>
                            <img className='sponsor-logo' src={mixi} alt='株式会社ミクシィ'　/>
                        </a>
                    </div>
                    <div>
                        <h3　className='sponsor-txt'>ドリンクスポンサー</h3>
                        <img className='drink' src={x} alt='支援者X'　/>
                    </div>
                </div>
            </Reveal>
        </div>
    )
}

export default Sponsor;