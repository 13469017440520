import React from 'react';
import camp from '../assets/image/footer-camp.png';
import company from '../assets/image/hackz-footer.png';
import mail from '../assets/image/mail.png';
import sns from '../assets/image/sns.png';
import '../assets/css/footer.css';

function Footer() {
    return (
        <footer>
            <img className='footer-camp' src={camp} alt='キャンプ'　/>
            <div className='footer'>
                <div className='menu'>
                    <nav>
                        <img className='company' src={company} alt='株式会社ハックツ'　/>
                        <p className='company-address'>
                            〒810-0073<br />
                            福岡県福岡市中央区<br />
                            舞鶴1丁目9-3 朝日プラザ天神 1204号室
                        </p>
                        <div className='footer-icon'>
                            <a href='https://forms.gle/L5cFEMcw9QnH7uCG7'>
                                <img className='mail' src={mail} alt='メール'　/>
                            </a>
                            <a href='https://twitter.com/Hackz_team'>
                                <img className='sns' src={sns} alt='Twitter'　/>
                            </a>
                        </div>
                    </nav>
                    <p className='privacy'>プライバシーポリシー</p>
                </div>
                <div className='copy'>
                    <p className='copy-txt'>“image: Freepik.com”このカバーのデザインはFreepik.comのリソースを使用しました。</p>
                    <p className='copy-txt'>
                        当サイトではアクセス動向を把握するため、Google Analyticsを利用しております。<br />
                        Google Analyticsは、Cookieを利用してお客様の情報を収集しますが、個人を特定する情報は収集しておりません。
                    </p>
                    <p className='copyright'>&copy;Hack'z inc</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
