import React from 'react';
import { Reveal } from "react-genie";
import { Animation } from "react-genie-styled-components";
import title from '../assets/image/point-title@2x.png';
import camp from '../assets/image/camp.png';
import cash from '../assets/image/cash.png';
import asset from '../assets/image/asset.png';
import first from '../assets/image/01@2x.png';
import second from '../assets/image/02@2x.png';
import third from '../assets/image/03@2x.png';
import '../assets/css/point.css';

const Point = () => {
  return (
    <div className='point'>
      <img className='point-title' src={title} alt='推しポイント'　/>
      <Reveal animation={Animation.FadeInUp} delay={100}>
        <div className='point-box'>
          <div className='point-copy'>
            <img className='number' src={first} alt='01'　/> 
            <div className='txt-box'>
              <h3 className='txt-box-title'><span>キャンプ場</span>で開発</h3>
              <p className='txt'>
                今回のハッカソンは、なんと「油山市民の森」というキャンプ場で開催！<br className='br-sp'/>
                さらにキャンプらしく、BBQなども準備させていただきます。
              </p>
              <p className='txt'>
                大自然の中で伸び伸びと開発をしてみませんか？<br className='br-sp'/>
                そしてみんなで開発とキャンプを同時に楽しみましょう！！！！
              </p>
            </div>
          </div>
          <img className='point-img' src={camp} alt='キャンプ'　/>
        </div>
      </Reveal>
      <Reveal animation={Animation.FadeInUp} delay={100}>
        <div className='point-box'>
          <div className='cash-box'>
            <img className='number' src={second} alt='02'　/> 
            <div className='txt-box'>
              <h3 className='txt-box-title'>参加費<span>無料</span></h3>
              <p className='txt'>
                ハックツが、キャンプ費用・食事代等を全て負担致しますので、<br className='br-sp'/>
                参加される皆さんは基本的に無料になります。<br className='br-sp'/>
                (交通費支給はありません。)
              </p>
              <p className='txt'>
                心置きなく楽しみましょう！
              </p>
            </div>
          </div>
          <img className='point-img' src={cash} alt='参加費無料'　/>
        </div>
      </Reveal>
      <Reveal animation={Animation.FadeInUp} delay={100}>
        <div className='point-box'>
          <div className='point-copy'>
            <img className='number' src={third} alt='03'　/> 
            <div className='txt-box'>
              <h3 className='txt-box-title'>圧倒的<span>成長</span>。</h3>
              <p className='txt'>
                我々は短期間(今回は二日間)だからこそ底力が発揮されます。<br className='br-sp'/>
                時には完成しないこともありますが、それでいいのです。<br className='br-sp'/>
                自分に今何ができないのか、次はどうしたらうまくいくか。<br className='br-sp'/>
              </p>
              <p className='txt'>
                それを知るため、次のステップに進むためにこのハッカソンを利用しましょう。<br className='br-sp'/>
                いざ、アウトプットの楽しさへ。
              </p>
            </div>
          </div>
          <img className='point-img' src={asset} alt='成長'　/>
        </div>
      </Reveal>
    </div>
  );
}

export default Point;