import React from 'react';
import { Reveal } from "react-genie";
import { Animation } from "react-genie-styled-components";
import title from '../assets/image/venue-title.png';
import venue from '../assets/image/venue.png';
import Map from '../assets/image/map.png';
import '../assets/css/venue.css';

const Venue = () => {
    return(
        <div className='venue'>
            <img className='venue-title' src={title} alt='会場'　/>
            <Reveal animation={Animation.FadeInUp} delay={100}>
                <div className='venue-box'>
                    <div className='venue-content'>
                        <a href='https://www.shimi-mori.com/'>
                            <img className='venue-image'　src={venue} alt='油山市民の森'　/>
                        </a>
                    </div>
                    <h3 className='venue-txt'>油山市民の森</h3>
                    <div className='address-box'>
                        <img className="icon" src={Map} alt='地図アイコン'　/>
                        <p className='address'>福岡県福岡市南区大字桧原８５５−４</p>
                    </div>
                    <a className='map-txt' target="_blank" rel="noopener noreferrer" href='https://www.google.com/maps/place/%E6%B2%B9%E5%B1%B1%E5%B8%82%E6%B0%91%E3%81%AE%E6%A3%AE/@33.516717,130.3733021,17z/data=!3m1!4b1!4m5!3m4!1s0x35419433f4835551:0x66bdeb44b0d82a53!8m2!3d33.516717!4d130.3754908'>
                        <p className='map'>（クリックするとGoogle Mapが開きます）</p>
                    </a>
                </div>
            </Reveal>
        </div>
    )
}

export default Venue;