import React from 'react';
import { Reveal } from "react-genie";
import { Animation } from "react-genie-styled-components";
import logo from '../assets/image/logo.png';
import '../assets/css/explain.css';

const Explain = () => {
  return (
    <Reveal animation={Animation.FadeInUp} delay={100}>
      <div className='explain'>
        <div className='explain-box'>
          <div className='explain-tittle'>
            <img className='company-logo' src={logo} alt='ハックツハッカソン'　/>
            <h3 className='explain-sub'>とは？</h3>
          </div>
          <p className='explain-txt'>
            短期間でプロダクトを設計/開発/発表を行うハッカソン。<br />
            その中でも特に「学生が楽しむこと」「地方にチャンスを作り続けること」に力を入れた<br />
            学生向けハッカソンイベントです。
          </p>
          <p className='explain-txt'>
            2019年3月から最低でも3ヶ月に1回のペースで開催しており、<br />
            その数は年間開催数で九州1位です。
          </p>
        </div>
      </div>         
    </Reveal>
  );
}

export default Explain;