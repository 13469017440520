import React, {Component} from 'react';
import Camp from '../assets/image/camp-hackathon.png';
import CampSp from '../assets/image/sp-camp.png';
import '../assets/css/top.css';

class Top extends Component {

    constructor(props) {
        super(props);
        this.node = React.createRef();
    }
    //スクロール量の初期値を設定
    state = {
        scrollTop: 0
    }

    //スクロール（render）をするたびに読み込まれる処理
    componentDidMount() {
        window.addEventListener('scroll', event => this.watchCurrentPosition(), true)
    }

    //renderが終わった後に読み込まれる処理
    componentWillUnmount() {
        window.removeEventListener('scroll')
    }

    //スクロール量を読み取ってくれる処理
    watchCurrentPosition() {
        //stateの値を再定義
        this.setState({
          scrollTop: Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop)
        })
        this.handleScroll();
    }

    //スクロール量を読み取った後の処理
    handleScroll = (e) => {
        //スクロール量によって返す値を変更
        if (this.state.scrollTop >= 1) {
        return  <a className='entry-button animate__animated animate__fadeInUp animate__fast'
                    href='https://hackz.connpass.com/event/183352/'>

                </a>
        } else {
            return null;
        }

    }

    render() {
        return (
            <div className='top'>
                <img className='top-img' src={Camp} alt='トップ画像'　/>
                <img className='sp-img' src={CampSp} alt='トップ画像'　/>
                <this.handleScroll />
            </div>
        );
    }
}

export default Top;