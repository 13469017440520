import React from 'react';
import { Reveal } from "react-genie";
import { Animation } from "react-genie-styled-components";
import title from '../assets/image/Theme-title.png';
import nature from '../assets/image/theme-nature.png';
import camp from '../assets/image/theme-camp.png';
import or from '../assets/image/or.png';
import '../assets/css/theme.css';

const Theme = () => {
    return(
        <div className='theme'>
            <img className='theme-title' src={title} alt='テーマ' />
            <Reveal animation={Animation.FadeInUp} delay={100}>
                <div className='theme-box'>
                    <img className='nature' src={nature} alt='自然で遊ぶ'　/>
                    <img className='or' src={or} alt='or'　/>
                    <img className='camp' src={camp} alt='キャンプで使える'　/>
                </div>
            </Reveal>
        </div>
    )
}

export default Theme;