import React from 'react';
import { Reveal } from "react-genie";
import { Animation } from "react-genie-styled-components";
import title from '../assets/image/schedule-title.png';
import schedule from '../assets/image/time-schedule.png';
import '../assets/css/schedule.css';

const Schedule = () => {
    return (
        <div className='schedule'>
            <img className='schedule-title' src={title} alt='スケジュール'　/>
            <Reveal animation={Animation.FadeInUp} delay={100}>
                <div className='schedule-box'>
                    <img className='board ' src={schedule} alt='タイムスケジュール'　/>
                    <div className='sp-schedule'>
                        <ul>
                            <li className='sp-day'>9/23</li>
                            <li>
                                <p>13:00~</p>
                                <p className='sp-txt'>集合、オープニング</p>
                            </li>
                            <li>
                                <p>14:00~</p>
                                <p className='sp-txt'>開発スタート</p>
                            </li>
                            <li>
                                <p>19:00~</p>
                                <p className='sp-txt'>夕食スタート</p>
                            </li>
                        </ul>
                        <ul>
                            <li className='sp-day'>9/24</li>
                            <li>
                                <p>10:30~</p>
                                <p className='sp-txt'>オープニング、開発</p>
                            </li>
                            <li>
                                <p>16:00~</p>
                                <p className='sp-txt'>発表開始 (チーム数によって調整)</p>
                            </li>
                            <li>
                                <p>18:00~</p>
                                <p className='sp-txt'>結果発表</p>
                            </li>
                            <li>
                                <p>18:30~</p>
                                <p className='sp-txt'>BBQ</p>
                            </li>
                        </ul>
                        <ul>
                            <li className='sp-day'>9/25</li>
                            <li>
                                <p>~16:00</p>
                                <p className='sp-txt'>キャンプを楽しむ！</p>
                            </li>
                            <li>
                                <p>16:00~</p>
                                <p className='sp-txt'>解散</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </Reveal>
        </div>
    );
}

export default Schedule;
